import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import Resultados from './components/resultados';
import Jogatina from './components/jogatina';
import Notificacoes from './components/notificacoes';
import reportWebVitals from './reportWebVitals';

const div_root = document.getElementById('root');
const root = ReactDOM.createRoot(div_root);

root.render(
    <React.StrictMode>
        <Jogatina />
        <Notificacoes />
        <Resultados />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
